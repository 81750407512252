@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Maven Pro';
  src: url('/mavenPro.ttf');
}

body {
  font-family: 'Maven Pro';
}

.auto-grid {
  --auto-grid-min-size: 16rem;
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 1fr)
  );
  grid-gap: 1rem;
}

.headroom {
  @apply mx-auto max-w-screen-2xl;
}

.cookie-banner {
  width: 600px;
}

.basic-table {
  width: 100%;
  border-collapse: collapse;
}
.basic-table th,
.basic-table td {
  padding: 8px 15px;
  text-align: left;
  border: 1px solid #ddd;
}

.sidebar-item-disabled {
  color: #ffffff;
  opacity: 0.3;
}

@media print {
  .no-print {
    display: none;
  }
}

/* .flatpickr-weekdaycontainer span:first-child {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.flatpickr-weekdaycontainer span:last-child {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

span.flatpickr-weekday {
  background: #efedf4;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.flatpickr-weekdaycontainer {
  padding: 1rem;
} */

.redClass {
  color: red !important;
}

.blackClass {
  color: rgb(8, 8, 8) !important;
}

.blueClass {
  color: blue !important;
}

.greyClass {
  color: rgba(70, 6, 6, 0.1) !important;
  cursor: default !important;
}

.orangeClass {
  color: orange !important;
}

.flatpickr-day.selected {
  background-color: #6d36e8 !important;
  border-color: #6d36e8 !important;
}

.flatpickr-day.hidden {
  visibility: visible;
  display: block;
}

.flatpickr-months {
  padding: 10px;
}

.flatpickr-prev-month,
.flatpickr-next-month {
  top: 20px !important;
}

.flatpickr-calendar {
  width: 100% !important;
  padding: 10px;
}

.selectedtext-green-500 {
  background-color: #6d36e8;
  color: white;
}

.selectedtext-green-500:hover {
  background-color: #6d36e8 !important;
  color: white !important;
}

.selectedtext-green-500:focus {
  background-color: #6d36e8 !important;
  color: white !important;
}

.flow-background {
  background: linear-gradient(-45deg, #6d36e8, #4acebf, #6d36e8);
  background-size: 400% 400%;
  animation: gradient 1.5s linear infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.flatpickr-rContainer {
  margin: 0 auto;
}

hr {
  color: lightgray;
}

.numInputWrapper {
  display: none !important;
}
